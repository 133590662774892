.top,
.privacy-policy,
.term,
.no-match,
.mypage,
.content-videos-index,
.content-video-show,
.completed-videos-index,
.set-user-created-video-wrapper {
  min-height: 1000px;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 100px;
}

.completed-videos-index {
  background-color: darkgray;
  min-height: 900px;
  width: 100%;
  padding-top: 35px;
  padding-bottom: 100px;
}

.mypage-container,
.content-video-show-container,
.completed-videos-container,
.loading-text-container {
  margin: 0 auto;
  width: 80%;
}

.content-videos-index-container {
  margin: 0 auto;
  width: 95%;
}

.completed-videos-container {
  margin: 0 auto;
  width: 100%;
}

.mypage-header,
.content-videos-index-header,
.content-videos-show-header,
.completed-videos-index-header {
  margin-bottom: 50px;
}

@media (max-width: 670px) {
}
