.header {
  height: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-container {
  width: 90%;
  margin: 0 auto;
}

.header-items-wrapper {
  position: relative;
  display: "inline-block";
  display: flex;
}

.header-title-logo-container {
  height: 90px;
  display: flex;
  align-items: center;
}

.header-title-logo {
  display: "inline-block";
  width: 350px;
  height: 70px;
  transition: all 0.3s;
}

.header-title-logo:hover {
  background-color: rgba(255, 238, 0, 0.56);
  top: -15px;
  box-shadow: 0 10px 3px rgba(0, 0, 0, 0.3);
}

.header-movie-camera {
  width: 70px;
  height: 60px;
  margin-left: 10px;
}

.header-iconlist {
  position: absolute;
  align-items: flex-end;
  right: 0;
  bottom: 0;
  display: flex;
}

.menu-box {
  width: 70px;
  margin-left: 5px;
}

.login-box {
  width: 70px;
  margin-right: 5px;
}

.menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  transition: 0.5s;
  transform: rotateZ(0deg);
}

.menu-button:hover {
  transform: rotateZ(45deg);
}

.login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  transition: 0.5s;
}

.login-button:hover {
  border-radius: 50%;
  background: yellow;
}

.login-button:active {
  border-radius: 50%;
  background: orange;
}

.logout-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  transition: 0.5s;
}

.logout-button:hover {
  border-radius: 50%;
  background: yellow;
}

.logout-button:active {
  border-radius: 50%;
  background: orange;
}
.moviefilm-line {
  width: 100%;
}

@media (max-width: 670px) {
  .header {
    height: 45px;
    margin-top: 0px;
  }

  .header-container {
    width: 95%;
    margin: 0 auto;
    padding-top: 10px;
  }

  .header-title-logo-container {
    height: 40px;
    width: 300px;
    display: flex;
    align-items: center;
  }

  .header-title-logo {
    display: "inline-block";
    width: 150px;
    height: 30px;
  }

  .header-movie-camera {
    width: 30px;
    height: 30px;
    margin-left: 0px;
  }

  .menu-box {
    width: 40px;
    margin-left: 5px;
  }

  .login-box {
    width: 40px;
    margin-right: 5px;
  }

  .menu-button {
    width: 40px;
    height: 40px;
  }

  .login-button {
    width: 40px;
    height: 40px;
  }

  .logout-button {
    width: 40px;
    height: 40px;
  }
}
