.content-video-show-first-part {
  width: 100%;
  display: flex;
  margin-bottom: 150px;
}

.content-video-show-card {
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
  height: 400px;
}

.example-video {
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
  height: 400px;
}

.content-video-show-second-part {
}

.lecture-photo {
  width: 70%;
}

@media screen and (max-width: 1100px) {
  .content-video-show-first-part {
    width: 100%;
    display: flex;
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 960px) {
  .content-video-show-first-part {
    width: 100%;
    display: flex;
    margin-bottom: 0px;
  }
}

@media (max-width: 670px) {
  .content-video-show-first-part {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: 250px;
  }

  .content-video-show-second-part {
    margin-top: 50px;
  }

  .content-video-show-card {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
    height: 300px;
  }

  .example-video {
    width: 100%;
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 10px;
    height: 250px;
  }

  .lecture-photo {
    width: 100%;
  }
}

@media (max-width: 390px) {
  .content-video-show-first-part {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }

  .content-video-show-second-part {
    margin-top: 50px;
  }

  .content-video-show-card {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    height: 300px;
  }

  .example-video {
    width: 100%;
    margin-top: 30px;
    margin-right: 0px;
    margin-left: 0px;
    height: 250px;
  }

  .lecture-photo {
    width: 100%;
  }
}
