.set-completed-video-wrapper {
  margin: 0 auto;
  padding-bottom: 50px;
}

.completed-video-design-frame {
  position: relative;
  margin: 0 auto;
  width: 800px;
  height: 600px;
}

.completed-video-design-frame-curtain-left {
  position: absolute;
  width: 220px;
  top: -170px;
  left: -80px;
}

.completed-video-design-frame-curtain-right {
  position: absolute;
  width: 220px;
  top: -170px;
  right: -80px;
}

.completed-video-mrmime-popcorn {
  position: absolute;
  width: 250px;
  bottom: 100px;
  right: -100px;
}

.completed-video-design-bottom {
  width: 800px;
  height: 200px;
}

.textarea {
  width: 100%;
}

.like-button-and-comment-post {
  display: flex;
  justify-content: center;
}

.like-button-and-like-amount {
  display: flex;
  justify-content: center;
}

.switch-like-buttons {
  align-items: center;
}

.like-amount {
  font-size: 30px;
  font-family: "Kaisei Opti", serif;
  text-shadow: 0 0 20px yellow;
  margin-left: 20px;
}

.like-button-and-comment-post > div {
  margin: 10px;
}

.like-amount-font {
  margin: 0px;
}

.comment-container {
  width: 95%;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .like-button-and-comment-post {
    flex-flow: column;
  }
}

@media (max-width: 900px) {
  .completed-video-design-frame {
    position: relative;
    margin: 0 auto;
    width: 600px;
    height: 600px;
  }

  .completed-video-design-frame-curtain-left {
    position: absolute;
    width: 220px;
    top: -170px;
    left: -80px;
  }

  .completed-video-design-frame-curtain-right {
    position: absolute;
    width: 220px;
    top: -170px;
    right: -80px;
  }

  .completed-video-mrmime-popcorn {
    position: absolute;
    width: 180px;
    bottom: 100px;
    right: -40px;
  }

  .completed-video {
    border-top: 40px solid;
    border-bottom: 30px solid;
    border-right: 25px solid;
    border-left: 25px solid;
    width: 400px;
    height: 225px;
    margin: 15px auto;
    box-shadow: rgba(255, 238, 0, 0.56) 0px 22px 70px 4px;
  }

  .completed-video-design-bottom {
    width: 600px;
    height: 120px;
  }
}

@media (max-width: 670px) {
  .completed-video-design-frame {
    position: relative;
    margin: 0 auto;
    width: 390px;
    height: 390px;
  }

  .completed-video-design-frame-curtain-left {
    width: 0px;
  }

  .completed-video-design-frame-curtain-right {
    width: 0px;
  }

  .completed-video-mrmime-popcorn {
    position: absolute;
    width: 120px;
    bottom: 0px;
    right: 10px;
  }

  .completed-video-design-bottom {
    width: 390px;
    height: 100px;
  }
}

@media (max-width: 400px) {
  .completed-video-design-frame {
    position: relative;
    margin: 0 auto;
    width: 100vw;
  }

  .completed-video-design-frame-curtain-left {
    width: 0px;
  }

  .completed-video-design-frame-curtain-right {
    width: 0px;
  }

  .completed-video-mrmime-popcorn {
    position: absolute;
    width: 30vw;
    bottom: 30px;
    right: 20px;
  }

  .completed-video-design-bottom {
    width: 100vw;
    height: 30vw;
  }
}
