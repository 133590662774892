.c {
  position: absolute;
  max-width: 500px;
  max-height: 500px;
  width: 130px;
  height: 220px;
  cursor: pointer;
  will-change: transform, opacity;
}

.front,
.back {
  background-size: cover;
}

.back {
  background-image: url(src/assets/design/door.png);
}

.front {
  background-image: url(src/assets/design/mrmime-come-in.png);
}

.container {
}

@media (max-width: 700px) {
  .c {
    width: 91px;
    height: 154px;
  }
}

@media (max-width: 460px) {
  .c {
    width: 65px;
    height: 110px;
  }
}
