.like {
  width: 70px;
  height: 70px;
}

.like:hover {
  opacity: 0.8;
}

.unlike {
  width: 90px;
  height: 90px;
}

.unlike:hover {
  opacity: 0.8;
}

.create {
  width: 90px;
  height: 90px;
  transform: rotateZ(0deg);
}

.create:hover {
  transition: 0.5s;
  width: 90px;
  height: 90px;
  transform: rotateZ(-20deg);
}

.create:active {
  transition: 0.5s;
  width: 110px;
  height: 110px;
  transform: rotateZ(-45deg);
}

.upload {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 700px) {
  .unlike {
    font-size: 13px;
    padding: 6px 4px 6px 4px;
  }
}
