.content-video-card-information-list {
  padding-bottom: 20px;
}

.card {
  margin: 25px auto 60px auto;
  width: 75%;
}

.information-box {
  padding: 1rem;
  box-shadow: 10px 10px 0 #e3b587;
  background-color: #314357;
  color: #e3b587;
}

.info-title {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
  font-size: 30px;
}

.info-description {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
  font-size: 15px;
}

@media (max-width: 670px) {
  .card {
    margin: 10px auto 20px auto;
  }

  .info-title {
    font-size: 20px;
  }

  .info-description {
    font-size: 15px;
  }
}

.information-box p {
  line-height: 1.5;
}

.content-video-card-button-list {
  margin: 1rem 1rem;
}

.content-video-card-button {
  text-align: center;
}

.thumbnail-frame {
  position: relative;
  margin-top: 20px;
}

.thumbnail-card {
  width: 100%;
}

.thumbnail-door {
  width: 20%;
  position: absolute;
  bottom: 240px;
  left: 50px;
}

.door-waiting {
  position: absolute;
  width: 130px;
  height: 220px;
  background-image: url(src/assets/design/door-waiting.png);
  background-size: cover;
}

.image {
  position: relative;
}

.image::before,
.image::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.image::before {
  top: -15px;
  right: -15px;
  border-width: 0 50px 50px 0;
  border-color: transparent #053e62 transparent transparent;
}

.image::after {
  bottom: -15px;
  left: -15px;
  border-width: 50px 0 0 50px;
  border-color: transparent transparent transparent #053e62;
}

@media (max-width: 700px) {
  .thumbnail-door {
    width: 20%;
    position: absolute;
    bottom: 164px;
    left: 40px;
  }

  .door-waiting {
    width: 91px;
    height: 154px;
  }
}

@media (max-width: 460px) {
  .thumbnail-door {
    width: 20%;
    position: absolute;
    bottom: 120px;
    left: 20px;
  }

  .door-waiting {
    width: 65px;
    height: 110px;
  }

  .image {
    position: relative;
  }

  .image::before,
  .image::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .image::before {
    top: -5px;
    right: -5px;
    border-width: 0 25px 25px 0;
    border-color: transparent #053e62 transparent transparent;
  }

  .image::after {
    bottom: -5px;
    left: -5px;
    border-width: 25px 0 0 25px;
    border-color: transparent transparent transparent #053e62;
  }
}
