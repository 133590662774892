.swing-videos-container {
  width: 70%;
  margin: 50px auto;
}

@media (max-width: 670px) {
  .swing-videos-container {
    margin: 10px auto;
  }
}
