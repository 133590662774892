.tutorial-list {
  width: 100%;
}

.tutorial-card-textbox {
  width: 70%;
  height: 600px;
  margin: 0px auto 0px auto;
  padding: 75px 0px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 5px 5px #314357;
}

.tutorial-box {
  display: flex;
}

.tutorial-image-box {
  align-items: center;
  width: 50%;
  margin-right: 10px;
  margin-left: 10px;
}

.tutorial-image {
  height: 450px;
  width: 400px;
  margin: 0 auto;
}

.tutorial-mrmime-box {
  align-items: center;
  width: 50%;
  padding-top: 50px;
  margin-right: 10px;
  margin-left: 10px;
}

.tutorial-mrmime {
  margin-top: 20px;
  width: 150px;
}

.foot-stamp {
  margin: 50px auto;
  width: 30%;
}

@media (max-width: 1200px) {
  .tutorial-box {
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .tutorial-card-textbox {
    padding: 30px 0px;
    height: 900px;
  }

  .tutorial-image-box,
  .tutorial-mrmime-box {
    width: 100%;
  }

  .tutorial-image-box {
    order: 1;
  }

  .tutorial-mrmime-box {
    order: 0;
    padding-top: 0px;
  }

  .tutorial-mrmime {
    margin-top: 0px;
    margin-bottom: 20px;
  }
}

@media (max-width: 670px) {
  .tutorial-card-textbox {
    width: 90%;
    height: 900px;
  }

  .tutorial-image {
    height: 360px;
    width: 320px;
  }
}

@media (max-width: 400px) {
  .tutorial-card-textbox {
    width: 90%;
    height: 750px;
  }

  .tutorial-image {
    height: 315px;
    width: 280px;
  }
}
