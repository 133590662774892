@import url("https://fonts.googleapis.com/css2?family=Kaisei+Opti&display=swap");

.text {
  white-space: pre-wrap;
}
.big-text {
  font-size: 20px;
  font-family: "Kaisei Opti", serif;
}

.loading-text {
  font-size: 30px;
  font-family: "Kaisei Opti", serif;
  color: #314357;
}

@media (max-width: 400px) {
  .big-text {
    font-family: "Kaisei Opti", serif;
  }
}

.card_textbox {
  width: 100%;
  height: auto;
  padding: 10px 10px;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 10px 5px 5px #314357;
}

.card_textbox-v2 {
  width: 100%;
  padding: 0 10px;
  height: auto;
  background: #314357;
  box-sizing: border-box;
  box-shadow: 10px 5px 5px #fff;
}

.card-loading {
  width: 250px;
  margin: 15px auto;
  padding: 5px;
  height: auto;
  background: #314357;
  box-sizing: border-box;
  box-shadow: 10px 5px 5px #fff;
}

.card-loading-text {
  font-family: "Kaisei Opti", serif;
  font-size: 25px;
  color: #fff;
}

.nom-match-text-first,
.mypage-text-first,
.term-text-first,
.privacy-policy-text-first,
.content-videos-index-text-first,
.content-videos-show-text-first,
.completed-videos-index-text-first {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 60px;
  color: #314357;
  text-shadow: 0 0 15px #ffffff;
}

@media (max-width: 670px) {
  .term-text-first,
  .privacy-policy-text-first,
  .mypage-text-first,
  .content-videos-index-text-first,
  .content-videos-show-text-first,
  .completed-videos-index-text-first {
    font-size: 35px;
  }
}

.content-videos-index-text-second {
}

.content-videos-show-text-second {
  display: inline-block;
  font-size: 20px;
  font-family: "Kaisei Opti", serif;
  color: #fff;
  white-space: pre-wrap;
}

.content-videos-show-text-second span {
  font-family: "AcademyEngravedLetPlain", sans-serif;
  font-size: 35px;
}

@media (max-width: 400px) {
  .content-videos-show-text-second {
    font-size: 20px;
    font-family: "Kaisei Opti", serif;
    white-space: pre-wrap;
  }

  .content-videos-show-text-second span {
    font-size: 30px;
  }
}

.content-videos-show-text-third {
  display: inline-block;
  font-size: 20px;
  font-family: "Kaisei Opti", serif;
  color: #fff;
  white-space: pre-wrap;
}

.content-videos-show-text-fourth {
  display: inline-block;
  font-size: 35px;
  font-family: "Kaisei Opti", serif;
  color: #fff;
  white-space: pre-wrap;
}

.content-videos-show-text-third span {
  font-family: "AcademyEngravedLetPlain", sans-serif;
  font-size: 35px;
}

.completed-videos-index-text-second {
}

.completed-video-card-text {
  font-size: 50px;
  color: #314357;
  text-shadow: 0 0 30px yellow;
}

@media (max-width: 670px) {
  .completed-video-card-text {
    font-size: 20px;
  }
}

.profile-name {
  font-family: "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", sans-serif;
}

.profile-email {
  font-family: "AcademyEngravedLetPlain", sans-serif;
}
