.set-user-created-video-wrapper {
  min-height: 900px;
}

.created-video-design-frame {
  position: relative;
  margin: 10px auto;
  width: 900px;
  height: 800px;
}

.user-created-video {
  margin: 15px auto;
  box-shadow: rgba(255, 238, 0, 0.56) 0px 22px 70px 4px;
}

.user-created-video {
  width: 800px;
  aspect-ratio: 16/9;
}

.created-video-button {
  margin-top: 100px;
}

@media (max-width: 900px) {
  .user-created-video {
    width: 600px;
    aspect-ratio: 16/9;
  }

  .created-video-button {
    margin-top: 0px;
  }
}

@media (max-width: 670px) {
  .user-created-video {
    width: 390px;
    aspect-ratio: 16/9;
  }
}

@media (max-width: 390px) {
  .user-created-video {
    width: 100vw;
    aspect-ratio: 16/9;
  }
}
