.responsive {
  width: 80%;
  aspect-ratio: 16 / 9;
  margin: 0 auto;
}

@media (max-width: 900px) {
  .responsive {
    width: 100%;
  }
}

@media (max-width: 670px) {
  .responsive {
    width: 100%;
  }
}

@media (max-width: 400px) {
  .responsive {
    width: 100%;
  }
}
